<template>
    <div>
        knowledge networks idle
    </div>
</template>

<script>
export default {
    name: 'KnowledgeNetworksIdle',
    components: {},
    mounted() {},
    methods: {},
}
</script>

<style scoped lang="scss"></style>
