var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.connectedUsers.includes('knowledgeNetworks_' + _vm.id))?_c('div',{ref:'userbox-' + _vm.id,staticClass:"user",class:'userbox-' + _vm.id},[(_vm.currentUser)?_c('LoginTimer',{key:'login-timer-knowledgeNetworks_' + _vm.id,attrs:{"unit":_vm.currentUser.unit,"user-uuid":_vm.currentUser.uuid,"active-area":_vm.$refs['userbox-' + _vm.id]}}):_vm._e(),_c('div',{staticClass:"collected-items-container",class:{ visible: _vm.userdataIdle }},[_c('div',{staticClass:"collected-items-container-inner"},[_vm._m(0),(_vm.itemsObj.length)?_c('div',{ref:"matter",staticClass:"user-element"},[_c('div',{staticClass:"user-element-inner"},_vm._l((_vm.itemsObj),function(item,i){return _c('span',{key:item.id,staticClass:"item",style:(_vm.getItemStyle(item, i)),on:{"click":function($event){return _vm.setItem(item, i)}}})}),0)]):_c('div',{staticClass:"no-collected-items"},[_c('h1',{staticClass:"text-black-50",domProps:{"innerHTML":_vm._s(
                        _vm.$store.getters['interfaceTexts/getInterfaceText'](
                            'collect_items',
                        )[_vm.lang]
                    )}})]),_c('div',{staticClass:"logout-btn m-2",on:{"click":function($event){return _vm.logoutFromUnit('knowledgeNetworks_' + _vm.id, _vm.id)}}},[_c('i',{staticClass:"fa fa-sign-out"})])])]),_c('div',{staticClass:"current-item",class:_vm.selectedItemId > -1 ? 'fadeInOut' : ''},[_c('div',{staticClass:"flickity-container"},[_c('div',{staticClass:"flickity-container-inner"},[(_vm.selectedItemId < 0)?_c('div',{staticClass:"user-coin"},[_c('span',[_vm._v(_vm._s(_vm.id))])]):_vm._e(),(_vm.selectedItemId > -1)?_c('div',{staticClass:"right-navi"},[_c('div',{staticClass:"close-btn m-2",on:{"click":function($event){return _vm.closeInfo(_vm.id - 1)}}},[_c('svg',{staticClass:"h-6 w-6",attrs:{"fill":"none","viewBox":"0 0 24 24","stroke":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"4","d":"M6 18L18 6M6 6l12 12"}})])])]):_vm._e(),(_vm.selectedItemObj)?_c('div',{staticClass:"title"},[_c('h1',[(
                                _vm.selectedItemObj.name &&
                                    _vm.selectedItemObj.name[_vm.lang]
                            )?_c('span',[_vm._v(" "+_vm._s(_vm.selectedItemObj.name[_vm.lang])+" ")]):(_vm.selectedItemObj.name.de)?_c('span',[_vm._v(" "+_vm._s(_vm.selectedItemObj.name.de)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.selectedItemObj.id)+" ")])])]):_vm._e(),_c('div',{staticClass:"slider-navigation w-100"},[(_vm.slideIndex > -1)?_c('div',{staticClass:"w-100 slider-navigation-wrap text-left"},[_c('button',{staticClass:"prev navi-btn",class:{
                                disabled: _vm.slideIndex === 0 || _vm.startCounter,
                            },attrs:{"disabled":_vm.slideIndex === 0 || _vm.startCounter},on:{"click":function($event){return _vm.setLocation('prev')}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'angle-left'],"size":"sm"}})],1),_c('button',{staticClass:"next navi-btn",class:{
                                disabled:
                                    _vm.slideIndex === _vm.slider.length - 1 ||
                                    _vm.startCounter,
                            },attrs:{"disabled":_vm.slideIndex === _vm.slider.length - 1 ||
                                    _vm.startCounter},on:{"click":function($event){return _vm.setLocation('next')}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'angle-right'],"size":"sm"}})],1)]):_vm._e()]),_c('div',{staticClass:"progress-container"},[_vm._m(1),_c('div',{staticClass:"steps"},_vm._l((_vm.slider),function(slide,i){return _c('div',{key:'slide-dot-' + i,staticClass:"step",class:i <= _vm.slideIndex ? 'completed' : '',attrs:{"id":i}})}),0)]),(_vm.startCounter || _vm.startCounterStoped)?_c('div',{staticClass:"round-time-bar",staticStyle:{"--duration":"5"},attrs:{"data-style":"smooth"}},[_c('div')]):_vm._e(),(_vm.slider)?_c('div',{ref:"flickity",staticClass:"flickity",on:{"click":function($event){return _vm.activateUser()}}},_vm._l((_vm.slider),function(slideInfo,index){return _c('div',{key:'cell_' + index,staticClass:"carousel-cell"},[_c('div',{staticClass:"cell-inner image"},[(
                                    slideInfo.content &&
                                        slideInfo.content.image &&
                                        slideInfo.content.image.global
                                            .imageAsset &&
                                        slideInfo.content.image.global
                                            .imageAsset.paths &&
                                        slideInfo.content.image.global
                                            .imageAsset.paths.url
                                )?_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":slideInfo.content.image.global
                                            .imageAsset.paths.url,"alt":""}}),_c('div',{staticClass:"copyright",class:{ visible: _vm.assetInfo }},[(
                                            slideInfo.content &&
                                                slideInfo.content.copyright
                                        )?_c('font-awesome-icon',{staticClass:"copyright-btn",attrs:{"icon":['far', 'info-circle'],"size":"lg"},on:{"click":function($event){return _vm.toggleInfo()}}}):_vm._e(),_c('div',{staticClass:"copyright-body"},[(
                                                slideInfo.content &&
                                                    slideInfo.content
                                                        .copyright &&
                                                    slideInfo.content
                                                        .copyright
                                            )?_c('p',{domProps:{"innerHTML":_vm._s(
                                                slideInfo.content.copyright[
                                                    _vm.lang
                                                ]
                                            )}}):_vm._e()])],1)]):(
                                    slideInfo.content &&
                                        slideInfo.content.quote &&
                                        slideInfo.content.quote[_vm.lang]
                                )?_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(slideInfo.content.quote[_vm.lang])}})]):_vm._e()]),(
                                slideInfo.content && slideInfo.content.text
                            )?_c('div',{staticClass:"cell-inner text mr-5"},[(
                                    slideInfo.content &&
                                        slideInfo.content.aspect
                                )?_c('h2',{domProps:{"innerHTML":_vm._s(slideInfo.content.aspect[_vm.lang])}}):_vm._e(),(
                                    slideInfo.content.place_time &&
                                        slideInfo.content.place_time[_vm.lang]
                                )?_c('p',[_vm._v(" "+_vm._s(slideInfo.content.place_time[_vm.lang])+" ")]):(
                                    slideInfo.content.place_time &&
                                        slideInfo.content.place_time.de
                                )?_c('p',[_vm._v(" "+_vm._s(slideInfo.content.place_time.de)+" ")]):_vm._e(),_c('p'),(slideInfo.content.text[_vm.lang])?_c('p',{domProps:{"innerHTML":_vm._s(slideInfo.content.text[_vm.lang])}}):(slideInfo.content.text.de)?_c('p',{domProps:{"innerHTML":_vm._s(slideInfo.content.text.de)}}):_vm._e()]):_vm._e()])}),0):_vm._e()])])])],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h1',[_c('span',[_vm._v(" Forum Wissen ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"progress"},[_c('div',{staticClass:"percent",staticStyle:{"width":"30%"}})])
}]

export { render, staticRenderFns }