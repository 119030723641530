var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bgbox",class:['bgbox-' + _vm.id, _vm.openObj.includes(_vm.id) ? 'open' : ''],on:{"click":function($event){return _vm.open(_vm.id)}}},[_c('div',{staticClass:"toggle",class:_vm.pulse ? 'visible' : ''},[_c('div',{staticClass:"absolute sonar",class:_vm.openObj.includes(_vm.id) || !_vm.pulse || _vm.playNowObj.includes(_vm.id)
                    ? 'hide'
                    : ''},[_c('span',{staticClass:"cbutton cbutton--effect-ivana cbutton--click"})]),_c('img',{staticClass:"btn cbutton cbutton--effect-ivana cbutton--click",class:_vm.openObj.includes(_vm.id) || !_vm.pulse || _vm.playNowObj.includes(_vm.id)
                    ? 'hide'
                    : '',attrs:{"src":require('@/assets/img/hand.svg'),"alt":""}}),(_vm.openObj.includes(_vm.id))?_c('img',{staticClass:"btn-close",attrs:{"src":require('@/assets/img/close.svg'),"alt":""}}):_vm._e()]),(_vm.openObj.includes(_vm.id))?_c('div',{staticClass:"box-content flex flex-col"},[_vm._m(0),_c('div',{staticClass:"score-wrapper w-100"},[_c('div',{staticClass:"store w-50"},[_c('h2',[_vm._v("Android")]),_c('img',{staticClass:"w-50",attrs:{"src":require('@/assets/img/qr_code_android.png'),"alt":""}})]),_c('div',{staticClass:"store w-50"},[_c('h2',[_vm._v("iOS")]),_c('img',{staticClass:"w-50",attrs:{"src":require('@/assets/img/qr_code_ios.png'),"alt":""}})]),_c('div',{staticClass:"play-now"},[(
                        !_vm.connectedUsers.includes('knowledgeNetworks_' + _vm.id)
                    )?_c('h2',{on:{"click":function($event){return _vm.loginWithoutMobile(_vm.id)}}},[_c('b',[_vm._v("jetzt spielen")]),_c('br'),_vm._v(" play now ")]):_vm._e()])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-header"},[_c('h1',[_vm._v("Download")])])
}]

export { render, staticRenderFns }