<template>
    <div class="knowledge-networks-container">
        <div id="mapContainer" class="vh-100"></div>
    </div>
</template>
<script>
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster'
import 'leaflet-edgebuffer'
import 'leaflet.motion/dist/leaflet.motion.min.js'
import '@elfalem/leaflet-curve'
import 'leaflet-routing-machine'
import 'leaflet-polylineoffset'

import {
    CachedTileLayer,
    IndexedDbTileCache,
} from '@yaga/leaflet-cached-tile-layer'

delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'map-marker-user-0.svg',
    iconUrl: 'map-marker-user-1.svg',
    shadowUrl: '',
})

export default {
    name: 'MapRoutesClear',
    props: {
        activeUsers: {
            type: Array,
            default: null,
        },
        locations: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            center: [51.533, 9.9],
            location: [51.534435278327166, 9.926366003609871],
            zoom: 4.66,
            mapUrl:
                'https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png',

            colors: [
                '#188ad9',
                '#910114',
                '#0a7502',
                '#df6800',
                '#f00187',
                '#01213f',
            ],

            clusterObject: [],
            showPreviewPath: false,
            users: [],
            usersData: [],
            route_speed: 3000,
            clusterRadius: 120,
            carCoordinates: null,
            routeWidth: 8,
            routeBorderWidth: 2,
            zoomToVisibleMarkers: true,
            mapLayer: null,
            navigationActive: {},
            markerGroup: null,
            markerStops: null,
            unVisibleMarkerGroup: null,
            clusterMarkers: null,
            previewMarker: null,
            currentAngle: 0,
            newTime: 0,
            oldTime: 0,
            animationId: 0,
            placeholder: [],
            routeHolder: [],
        }
    },
    computed: {
        map() {
            return L.map('mapContainer', {
                // renderer: L.svg(),
                // preferCanvas: false,
                // renderer: L.canvas(),
                // preferCanvas: true,
                center: this.center,
                zoom: this.zoom,
                attributionControl: false,
                zoomControl: false,
                dragging: false,
                boxZoom: false,
                rotate: true,
                doubleClickZoom: false,
                scrollWheelZoom: false,
                tap: false,
                touchZoom: false,
                bearing: 0,
                zoomSnap: 0.01,
                // zoomSnap: 1,
                maxZoom: 18,
                minZoom: 4.66,
                zoomAnimation: true,
                worldCopyJump: true,
                // noWrap: false,
            })
        },
        iconMuseum() {
            return L.icon({
                iconUrl: require('@/assets/img/fowi-logo.png'),
                iconSize: [40],
                iconAnchor: [40, 100],
                className: 'museum',
            })
        },
        isAUserLoggedIn() {
            return this.$store.getters.isAUserLoggedIn
        },
        carRoutes() {
            return this.$store.state.knowledgeNetworks.carRoutes
        },
    },
    watch: {
        isAUserLoggedIn(val) {
            if (!val) {
                this.rotateCamera()
                this.unVisibleMarkerGroup.eachLayer(l => {
                    if (l.options.userId !== 10) {
                        this.unVisibleMarkerGroup.removeLayer(l)
                    }
                })

                this.markerGroup.eachLayer(l => {
                    this.markerGroup.removeLayer(l)
                })
            }
        },
        '$store.state.users': {
            handler(users) {
                if (users) {
                    // this.stopRotation()
                    let activeUsers = []
                    Object.keys(users).forEach(el => {
                        activeUsers.push(
                            parseInt(el.split('knowledgeNetworks_')[1]),
                        )
                    })
                    this.markerGroup.eachLayer(l => {
                        if (
                            !activeUsers.includes(l.options.userId + 1) &&
                            l.options.userId !== undefined
                        ) {
                            // console.log(l.options.userId + 1)
                            this.removeLayer(l.options.userId)
                        }
                    })
                }
            },
        },
        routeHolder(val) {
            if (window.electronApi) {
                if (this.carRoutes) {
                    let item = this.carRoutes.find(
                        r =>
                            r.pid === val.user.pid &&
                            r.position === val.user.position,
                    )
                    // console.log(item)
                    if (!item) {
                        let arrayRoutes = this.carRoutes
                        arrayRoutes.push({
                            coordinates: val.route,
                            pid: val.user.pid,
                            position: val.user.position,
                        })
                        // update JSON file in downloads/Medienstation/fowi
                        window.electronApi.send('saveRouteData', {
                            routes: arrayRoutes,
                        })
                        this.$store.dispatch(
                            'knowledgeNetworks/setCarRoutes',
                            arrayRoutes,
                        )
                    }
                } else {
                    let arrayRoutes = []
                    arrayRoutes.push({
                        coordinates: val.route,
                        pid: val.user.pid,
                        position: val.user.position,
                    })
                    // update JSON file in downloads/Medienstation/fowi
                    window.electronApi.send('saveRouteData', {
                        routes: arrayRoutes,
                    })
                    this.$store.dispatch(
                        'knowledgeNetworks/setCarRoutes',
                        arrayRoutes,
                    )
                }
            }
        },
    },
    mounted() {
        // add map Layer
        this.mapLayer = L.tileLayer(this.mapUrl, {
            // useCache: true,
            // edgeBufferTiles: 5,
            // worldCopyJump: true,
        }).addTo(this.map)

        // const originalOnAdd = L.Renderer.prototype.onAdd
        // L.Renderer.prototype.onAdd = function() {
        //     originalOnAdd.call(this)
        //     if (!this._zoomAnimated) {
        //         L.DomUtil.addClass(this._container, 'leaflet-zoom-animated')
        //     }
        // }

        // additional Layar Groups
        this.markerGroup = L.featureGroup().addTo(this.map)
        this.markerStops = L.featureGroup().addTo(this.map)
        this.previewMarker = L.featureGroup().addTo(this.map)

        // this layer is for the bounding fit
        this.unVisibleMarkerGroup = L.featureGroup().addTo(this.map)

        // current position FoWi-Göttingen
        this.setMuseumMarker()

        // init clustering for the visible user markers
        this.setClustering()

        // tileCache
        // this.initTilesCache()

        if (window.electronApi) {
            //read saved Coordinates from downloads/Medienstation/fowi
            window.electronApi.send('readRouteData')
            //save file data to store
            window.electronApi.receive('setRouteData', data => {
                this.$store.dispatch(
                    'knowledgeNetworks/setCarRoutes',
                    data.routes,
                )
            })
        }
    },
    methods: {
        initTilesCache() {
            const leafletCachedTileLayer = new CachedTileLayer(this.mapUrl, {
                databaseName: 'fowi-tile-cache-data', // optional
                databaseVersion: 1, // optional
                objectStoreName: 'OSM', // optional
                crawlDelay: 500, // optional
                maxAge: 1000 * 60 * 60 * 24 * 7, // optional
            }).addTo(this.map)

            const tileCache = new IndexedDbTileCache()

            // get a tile from cache or download if not available:
            tileCache.getTileAsDataUrl({ x: 0, y: 0, z: 0 }).then(
                function(dataUrl) {
                    const img = document.createElement('img')
                    img.src = dataUrl
                    document.body.appendChild(img)
                },
                function(err) {
                    console.error(err)
                },
            )
        },
        // intro animation rotate
        rotateCamera() {
            this.currentAngle = 0
            this.oldTime = new Date().getTime()
            window.webkitRequestAnimationFrame(this.updateRotation)
        },
        // intro animation update
        updateRotation() {
            this.newTime = new Date().getTime()
            let diff = this.newTime - this.oldTime
            this.oldTime = this.newTime
            this.currentAngle += diff * 0.02
            if (this.map !== undefined) {
                //this.map.setBearing(this.currentAngle)
                this.animationId = window.webkitRequestAnimationFrame(
                    this.updateRotation,
                )
            }
        },
        // intro animation stop
        stopRotation() {
            cancelAnimationFrame(this.animationId)
            if (this.currentAngle > 90 && this.currentAngle < 270) {
                //this.map.setBearing(180)
            } else {
                //this.map.setBearing(0)
            }
            this.map.flyTo(this.center, 6, {
                animate: true,
                duration: 4,
            })
        },
        // set museum-marker in the center (always visible)
        setMuseumMarker() {
            let marker = L.marker(this.location, {
                icon: this.iconMuseum,
                userId: 10,
                museum: true,
                pid: 'museum',
            }).addTo(this.map)

            // this layer is for the bounding fit important
            this.unVisibleMarkerGroup.addLayer(marker)
        },
        //set the offset position for each user (same route offset)
        setUserData(locationData) {
            let offsets = []
            this.usersData.forEach(user => {
                if (user.item === locationData.pid) {
                    offsets.push(user.position)
                }
            })

            this.usersData.push({
                user: locationData.userId,
                item: locationData.pid,
                position: offsets
                    ? this.diff([0, 1, 2, 3, 4, 5], offsets)[0]
                    : 0,
            })
        },
        setItemStartPosition(locationData) {
            this.setUserData(locationData)

            if (locationData.points[0].subpoints[0].content) {
                let step = 0
                let startPoint = [
                    parseFloat(
                        locationData.points[step].subpoints[0].content.latitude
                            .global,
                    ),
                    parseFloat(
                        locationData.points[step].subpoints[0].content.longitude
                            .global,
                    ),
                ]

                let endPoint = ''
                if (locationData.points[step].subpoints[1]) {
                    endPoint = [
                        parseFloat(
                            locationData.points[step].subpoints[1].content
                                .latitude.global,
                        ),
                        parseFloat(
                            locationData.points[step].subpoints[1].content
                                .longitude.global,
                        ),
                    ]
                }
                let counter = 1
                // console.log(startPoint)
                let endMarker = L.marker(startPoint, {
                    start: startPoint.toString(),
                    end: endPoint.toString(),
                    pid: locationData.pid + '_step-0',
                    clusterKey: locationData.pid,
                    last: true,
                    remove: true,
                    color: this.colors[locationData.userId],
                    userId: locationData.userId,
                })
                // console.log(endMarker)
                this.markerGroup.addLayer(endMarker)
                this.clusterMarkers.addLayer(endMarker)
                this.unVisibleMarkerGroup.addLayer(endMarker)

                let progressData = this.$store.state.knowledgeNetworks.usersProgress.find(
                    p =>
                        p.userId === 'knowledgeNetworks_' + locationData.userId,
                )
                // console.log(progressData)

                let usersProgress = {
                    userId: 'knowledgeNetworks_' + locationData.userId,
                    step: 0,
                    // offset: this.getUserOffset(locationData.userId),
                    sliderIndex: locationData.position,
                    selectedPid: locationData.pid,
                    stops: locationData.points.slice(0, 1),
                }

                this.$store.dispatch(
                    'knowledgeNetworks/setUsersProgress',
                    usersProgress,
                )

                let marker = L.marker(startPoint, {
                    icon: new L.DivIcon({
                        className: 'unvisible',
                        html: '<div class="unvisible"></div>',
                    }),
                    className: 'unvisible',
                    name: locationData.userId + '-' + 0,
                    userId: locationData.userId,
                })

                this.unVisibleMarkerGroup.addLayer(marker)

                // refresh clustered circles
                this.clusterMarkers.refreshClusters()
                // console.log('refreshClusters 1')

                //update map bounding
                this.fitMap()
            }
        },
        updateProgress(locationData, direction) {
            let progressData = this.$store.state.knowledgeNetworks.usersProgress.find(
                p => p.userId === 'knowledgeNetworks_' + locationData.userId,
            )
            let step = -1
            if (progressData) {
                step = progressData.step
            }

            if (direction === 'next') {
                step++
            } else {
                step--
            }

            let usersProgress = {
                userId: 'knowledgeNetworks_' + locationData.userId,
                step: step,
                offset: progressData.offset || 0,
                sliderIndex: locationData.position,
                selectedPid: locationData.pid,
                stops: locationData.points.slice(0, step++),
            }

            this.$store.dispatch(
                'knowledgeNetworks/setUsersProgress',
                usersProgress,
            )
        },
        async createRoute(pointStart, pointEnd, color, locationData) {
            let progressData = await this.$store.state.knowledgeNetworks.usersProgress.find(
                p => p.userId === 'knowledgeNetworks_' + locationData.userId,
            )
            let step = progressData.step
            // console.log(locationData.points[step])

            let direction = locationData.points[step].content
                ?.subpoints_direction?.global
                ? locationData.points[step].content.subpoints_direction.global
                : 'route'

            if (direction === 'route') {
                let routeCoordinates = []
                let routeObject = []
                let sequences = []

                locationData.points[step].subpoints.forEach((el, i) => {
                    // console.log(el)

                    let type = ''
                    if (el.content?.type?.global) {
                        type = el.content.type.global
                    }

                    if (i < 1) {
                        let lineType = 'line'
                        if (el.content?.line_type?.global) {
                            lineType = el.content.line_type.global
                        }
                        // console.log(lineType)
                        routeCoordinates.push({
                            lat: parseFloat(el.content.latitude.global),
                            lng: parseFloat(el.content.longitude.global),
                        })
                        let point = {
                            lat: parseFloat(el.content.latitude.global),
                            lng: parseFloat(el.content.longitude.global),
                        }
                        let endMarker = L.marker(point, {
                            // start: startPoint.toString(),
                            // end: endPoint.toString(),
                            pid: locationData.pid + step,
                            clusterKey: locationData.pid,
                            last: true,
                            remove: true,
                            color: this.colors[locationData.userId],
                            userId: locationData.userId,
                        })
                        // console.log(endMarker)
                        this.markerGroup.addLayer(endMarker)
                        this.clusterMarkers.addLayer(endMarker)
                        this.unVisibleMarkerGroup.addLayer(endMarker)
                        this.clusterMarkers.refreshClusters()
                        // console.log('refreshClusters 2')
                    }
                    if (i > 0) {
                        let lineType = 'line'
                        if (el.content?.line_type?.global) {
                            lineType = el.content.line_type.global
                        }
                        // let type = ''
                        // if (el.content?.type?.global) {
                        //     type = el.content.type.global
                        // }
                        // console.log(lineType)
                        // console.log(type)
                        let markerCoordinatesEnd = {
                            lat: parseFloat(el.content.latitude.global),
                            lng: parseFloat(el.content.longitude.global),
                            // type: lineType,
                        }

                        let crossMapBoundaries = ''
                        // if (locationData.points[step].subpoints[i + 1]) {
                        let markerCoordinatesStart = {
                            lat: parseFloat(
                                locationData.points[step].subpoints[i - 1]
                                    .content.latitude.global,
                            ),
                            lng: parseFloat(
                                locationData.points[step].subpoints[i - 1]
                                    .content.longitude.global,
                            ),
                            // type: lineType,
                        }

                        crossMapBoundaries = this.addLineToMap(
                            [
                                markerCoordinatesStart.lat,
                                markerCoordinatesStart.lng,
                            ],
                            [
                                markerCoordinatesEnd.lat,
                                markerCoordinatesEnd.lng,
                            ],
                        )
                        // console.log(crossMapBoundaries)
                        //routeCoordinates.push(markerCoordinates)

                        if (crossMapBoundaries) {
                            sequences.push([
                                markerCoordinatesStart,
                                {
                                    lat: crossMapBoundaries[0][0],
                                    lng: crossMapBoundaries[0][1],
                                },
                            ])
                            routeObject.push({ lineType: lineType, type: type })
                            sequences.push([
                                {
                                    lat: crossMapBoundaries[1][0],
                                    lng: crossMapBoundaries[1][1],
                                },
                                markerCoordinatesEnd,
                            ])
                            routeObject.push({ lineType: lineType, type: type })
                        } else {
                            routeCoordinates.push([
                                markerCoordinatesStart,
                                markerCoordinatesEnd,
                            ])
                            sequences.push([
                                markerCoordinatesStart,
                                markerCoordinatesEnd,
                            ])
                            routeObject.push({ lineType: lineType, type: type })
                        }
                    }
                })

                if (sequences.length) {
                    if (routeObject[0].type === 'car') {
                        let item = this.carRoutes.find(
                            r =>
                                r.pid === locationData.pid &&
                                r.position === locationData.position,
                        )
                        // console.log(item)
                        if (item) {
                            sequences = [item.coordinates]
                        } else {
                            this.carRoute(pointStart, pointEnd, locationData)
                        }
                    }
                    if (routeObject[0].type === 'plane') {
                        // console.log('plane')
                        let coords = this.planeRoute(
                            pointStart,
                            pointEnd,
                            locationData,
                        )
                        sequences = [coords]
                    }
                    // console.log(sequences)
                    let slideRoute = this.createSeq(
                        sequences,
                        // [this.routeHolder.route],
                        locationData,
                        progressData,
                        routeObject,
                    )
                    this.drawRoute(
                        slideRoute,
                        locationData,
                        direction,
                        sequences,
                    )
                    let type = 'boat'
                    if (locationData.points[step].content?.type?.global) {
                        type = locationData.points[step].content.type.global
                    }
                    // console.log(type)
                    // console.log('sequences')
                } else {
                    // console.log('not sequences')
                    // console.log(routeCoordinates)
                    if (locationData.points[step].subpoints.length > 1) {
                        let routeCoordinates = []

                        let lineType = 'line'

                        // console.log(locationData.points[step].subpoints)
                        if (
                            locationData.points[step].content?.line_type?.global
                        ) {
                            lineType =
                                locationData.points[step].content.line_type
                                    .global
                        }
                        let routeObject = []
                        let type = ''
                        if (locationData.points[step].content?.type?.global) {
                            type = locationData.points[step].content.type.global
                        }

                        // console.log(type)
                        routeObject.push({ lineType: lineType, type: type })
                        //routeCoordinates.forEach(seq => {
                        // console.log(seq)
                        // console.log(sequences)
                        // if (type === 'car') {
                        //     this.carRoute(
                        //         sequences[0],
                        //         sequences[1],
                        //         locationData,
                        //     )
                        // } else {
                        let slideRoute = this.createSeq(
                            sequences,
                            locationData,
                            progressData,
                            routeObject,
                        )
                        // console.log(slideRoute)
                        routeCoordinates.push(slideRoute)
                        this.drawRoute(
                            routeCoordinates,
                            locationData,
                            direction,
                            sequences,
                        )
                    }
                }
            } else {
                if (locationData.points[step].subpoints.length > 1) {
                    locationData.points[step].subpoints.forEach((el, i) => {
                        // console.log(el.content.line_type.global)
                        let lineType = 'line'
                        if (
                            locationData.points[step].content?.line_type?.global
                        ) {
                            lineType =
                                locationData.points[step].content.line_type
                                    .global
                        }
                        let type = 'boat'
                        if (locationData.points[step].content?.type?.global) {
                            type = locationData.points[step].content.type.global
                        }
                        // console.log(type)
                        let routeObject = []
                        routeObject.push({ lineType: lineType, type: type })
                        if (i > 0) {
                            // target Point for outgoing and incoming
                            let pointStart = {
                                lat:
                                    locationData.points[step].subpoints[0]
                                        .content.latitude.global,
                                lng:
                                    locationData.points[step].subpoints[0]
                                        .content.longitude.global,
                            }

                            // start- or end-point for outgoing and incoming
                            let pointEnd = {
                                lat: parseFloat(el.content.latitude.global),
                                lng: parseFloat(el.content.longitude.global),
                            }

                            let routeCoordinates = [pointStart, pointEnd]
                            if (direction === 'incoming') {
                                routeCoordinates = [pointEnd, pointStart]
                            }
                            // console.log(this.routeHolder)
                            let singleMotionRoute = this.createSeq(
                                [routeCoordinates],
                                // [this.routeHolder.route],
                                locationData,
                                progressData,
                                routeObject,
                            )
                            // console.log(routeCoordinates)
                            // console.log(singleMotionRoute)
                            this.drawRoute(
                                singleMotionRoute,
                                locationData,
                                direction,
                                [routeCoordinates],
                            )
                        }
                    })
                }
            }
        },

        createSeq(coordinates, locationData, progressData, routeObject) {
            let dashArray = ''
            let dashOffset = ''

            let singleMarkerStyle =
                'border-color:' + this.colors[locationData.userId]

            // console.log(coordinates)
            let seq = []
            coordinates.forEach((el, i) => {
                let lineType = routeObject[i]?.lineType || 'line'
                // console.log(lineType)
                switch (lineType) {
                    case 'invisible':
                        dashArray = ''
                        dashOffset = ''
                        break
                    case 'dashed':
                        dashArray = '10, 15'
                        dashOffset = '10'
                        break
                    case 'dashpoint':
                        dashArray = '10, 15, 1, 15'
                        dashOffset = '10'
                }
                let pin =
                    '<div class="circle-marker circle-marker-1"><div class="colorWheel">' +
                    '<span class="color" style="border-top-color:' +
                    this.colors[locationData.userId] +
                    ';' +
                    singleMarkerStyle +
                    '"></span></div></div>'
                let addClass = '' // type === 'car' ? 'carline ' : ' '
                let offsetCounter =
                    this.getUserOffset(locationData.userId) * this.routeWidth
                // console.log(lineType)
                let singleLine = L.motion
                    .polyline(
                        el,
                        {
                            className:
                                addClass +
                                'line-' +
                                progressData.offset +
                                ' ' +
                                ' outlined animate user-' +
                                locationData.userId,
                            userId: locationData.userId,
                            subRoute: true, //subRoute,
                            name: locationData.userId + '-' + progressData.step,
                            weight: this.routeWidth - this.routeBorderWidth,
                            opacity: 1,
                            color:
                                lineType !== 'invisible'
                                    ? this.colors[locationData.userId]
                                    : '',
                            // offset: type !== 'car' ? offsetCounter : '0',
                            offset: offsetCounter,
                            dashArray: dashArray,
                            dashOffset: dashOffset,
                        },
                        {
                            auto: false,
                            duration: 1000,
                            easing: L.Motion.Ease.easeInOutQuad,
                        },
                        {
                            removeOnEnd: true,
                            icon: L.divIcon({
                                html: pin,
                                // iconSize: L.point(19, 24),
                                iconSize: L.point(60, 60),
                            }),
                        },
                    )
                    .addTo(this.map)
                this.markerGroup.addLayer(singleLine)
                seq.push(singleLine)
            })
            // console.log(seq)

            return seq
        },

        drawRoute(coordinates, locationData, direction, coo) {
            // console.log(coordinates)
            // && coordinates.length > 1
            if (direction === 'route') {
                let seqGroup = L.motion.seq(coordinates).addTo(this.map)
                this.fitMap()
                this.markerGroup.addLayer(seqGroup)

                this.map.once('moveend', () => {
                    seqGroup.motionStart()
                })

                seqGroup.on('motion-started', e => {
                    // console.log('START')
                })
                seqGroup.on('motion-ended', e => {
                    let point = null
                    if (coo.length > 1) {
                        point = {
                            lat: parseFloat(coo[coo.length - 1][1].lat),
                            lng: parseFloat(coo[coo.length - 1][1].lng),
                        }
                    } else {
                        point = {
                            lat: parseFloat(coo[0][coo[0].length - 1].lat),
                            lng: parseFloat(coo[0][coo[0].length - 1].lng),
                        }
                    }
                    // console.log(point)
                    let endMarker = L.marker(point, {
                        pid: locationData.pid + '_step-0',
                        clusterKey: locationData.pid,
                        last: true,
                        remove: true,
                        color: this.colors[locationData.userId],
                        userId: locationData.userId,
                        class: 'animated-marker',
                    })
                    this.markerGroup.addLayer(endMarker)
                    this.clusterMarkers.addLayer(endMarker)
                    this.unVisibleMarkerGroup.addLayer(endMarker)
                    this.clusterMarkers.refreshClusters()
                    // console.log('refreshClusters 3')
                })
            } else {
                let route = L.motion.seq(coordinates).addTo(this.map)

                this.markerGroup.addLayer(route)
                this.map.once('moveend', () => {
                    route.motionStart()
                })
                route.on('motion-ended', e => {
                    if (coordinates[coordinates.length - 1]) {
                        let point = {
                            lat: parseFloat(coo[coo.length - 1][1].lat),
                            lng: parseFloat(coo[coo.length - 1][1].lng),
                        }
                        let endMarker = L.marker(point, {
                            pid: locationData.pid + '_step-0',
                            clusterKey: locationData.pid,
                            last: true,
                            remove: true,
                            color: this.colors[locationData.userId],
                            userId: locationData.userId,
                            class: 'animated-marker',
                        })
                        // console.log(endMarker)
                        this.markerGroup.addLayer(endMarker)
                        this.clusterMarkers.addLayer(endMarker)
                        this.unVisibleMarkerGroup.addLayer(endMarker)
                        this.clusterMarkers.refreshClusters()
                        // console.log('refreshClusters 4')
                    }
                })
            }
        },
        hideSubRoute(locationData) {
            document
                .querySelectorAll('path.user-' + locationData.userId)
                .forEach(el => {
                    el.classList.add('hiddenline')
                })

            this.markerGroup.eachLayer(l => {
                if (l.options.userId === locationData.userId) {
                    this.markerGroup.removeLayer(l)
                }
            })
            this.clusterMarkers.eachLayer(l => {
                if (l.options.userId === locationData.userId) {
                    this.clusterMarkers.removeLayer(l)
                }
            })
            this.clusterMarkers.refreshClusters()
            // console.log('refreshClusters 5')
            this.unVisibleMarkerGroup.eachLayer(l => {
                if (l.options.userId === locationData.userId) {
                    this.unVisibleMarkerGroup.removeLayer(l)
                }
            })
        },
        goToPrevLocation: async function(locationData) {
            this.updateProgress(locationData, 'prev')

            this.dir = 'prev'
            this.hideSubRoute(locationData)

            let progressData = await this.$store.state.knowledgeNetworks.usersProgress.find(
                p => p.userId === 'knowledgeNetworks_' + locationData.userId,
            )
            let step = 0
            if (progressData) {
                step = progressData.step
            }

            locationData.points[step].subpoints.forEach(el => {
                // console.log(el)
                let markerCoordinates = [
                    parseFloat(el.content.latitude?.global),
                    parseFloat(el.content.longitude?.global),
                ]
                var marker = L.marker(markerCoordinates, {
                    icon: new L.DivIcon({
                        className: 'unvisible',
                        html: '<div class="unvisible"></div>',
                    }),
                    className: 'unvisible',
                    name: locationData.userId + '-' + step,
                    userId: locationData.userId,
                }) //.addTo(this.map)
                this.unVisibleMarkerGroup.addLayer(marker)
            })

            this.fitMap()

            this.markerGroup.eachLayer(l => {
                if (l.options.name === locationData.userId + '-' + (step + 1)) {
                    this.markerGroup.removeLayer(l)
                }
            })
            this.clusterMarkers.eachLayer(l => {
                if (
                    l.options.userId === locationData.userId &&
                    l.options.name === locationData.userId + '-' + step
                ) {
                    this.clusterMarkers.removeLayer(l)
                }
            })
            let subpointsCounter = locationData.points[step].subpoints.length

            let startPoint = [
                parseFloat(
                    locationData.points[step].subpoints[0].content.latitude
                        .global,
                ),
                parseFloat(
                    locationData.points[step].subpoints[0].content.longitude
                        .global,
                ),
            ]

            let endPoint = [
                parseFloat(
                    locationData.points[step].subpoints[subpointsCounter - 1]
                        .content.latitude.global,
                ),
                parseFloat(
                    locationData.points[step].subpoints[subpointsCounter - 1]
                        .content.longitude.global,
                ),
            ]
            // draw the new route
            this.createRoute(
                startPoint,
                endPoint,
                this.colors[locationData.userId],
                locationData,
            )

            this.clusterMarkers.refreshClusters()
            // console.log('refreshClusters 6')
        },
        goToNextLocation: async function(locationData) {
            this.updateProgress(locationData, 'next')

            this.dir = 'next'
            this.hideSubRoute(locationData)

            let progressData = await this.$store.state.knowledgeNetworks.usersProgress.find(
                p => p.userId === 'knowledgeNetworks_' + locationData.userId,
            )
            let step = 0
            if (progressData) {
                step = progressData.step
            }

            let subpointsCounter = locationData.points[step].subpoints.length

            locationData.points[step].subpoints.forEach(el => {
                // console.log(el)
                let markerCoordinates = [
                    parseFloat(el.content.latitude?.global),
                    parseFloat(el.content.longitude?.global),
                ]
                var marker = L.marker(markerCoordinates, {
                    icon: new L.DivIcon({
                        className: 'unvisible',
                        html: '<div class="unvisible"></div>',
                    }),
                    className: 'unvisible',
                    name: locationData.userId + '-' + step,
                    userId: locationData.userId,
                }) //.addTo(this.map)
                this.unVisibleMarkerGroup.addLayer(marker)
            })
            let startPoint = [
                parseFloat(
                    locationData.points[step].subpoints[0].content.latitude
                        .global,
                ),
                parseFloat(
                    locationData.points[step].subpoints[0].content.longitude
                        .global,
                ),
            ]

            let endPoint = [
                parseFloat(
                    locationData.points[step].subpoints[subpointsCounter - 1]
                        .content.latitude.global,
                ),
                parseFloat(
                    locationData.points[step].subpoints[subpointsCounter - 1]
                        .content.longitude.global,
                ),
            ]
            this.fitMap()

            // draw the new route
            this.createRoute(
                startPoint,
                endPoint,
                this.colors[locationData.userId],
                locationData,
            )

            this.clusterMarkers.refreshClusters()
            // console.log('refreshClusters 7')
        },
        removeLayer(userId) {
            // console.log(this.usersData)
            let index = -1

            this.usersData.forEach((user, i) => {
                if (user.user === userId) {
                    index = i
                }
            })
            if (index > -1) {
                this.usersData.splice(index, 1)
            }
            this.markerGroup.eachLayer(l => {
                if (l.options.userId === userId) {
                    this.markerGroup.removeLayer(l)
                }
                if (l.options.preview) {
                    this.markerGroup.removeLayer(l)
                }
            })
            this.previewMarker.eachLayer(l => {
                if (l.options.userId === userId) {
                    this.previewMarker.removeLayer(l)
                }
            })
            this.clusterMarkers.eachLayer(l => {
                if (l.options.userId === userId) {
                    this.clusterMarkers.removeLayer(l)
                }
            })
            if (this.zoomToVisibleMarkers) {
                this.unVisibleMarkerGroup.eachLayer(l => {
                    if (l.options.userId === userId) {
                        this.unVisibleMarkerGroup.removeLayer(l)
                    }
                })
            }

            // this.currentStep = 0
            // this.findMarkerGroups()
            // this.clusterMarkers.refreshClusters()
            // this.removeElementsByClass('user-' + userId)
            this.$store.dispatch('knowledgeNetworks/resetUsersProgress', userId)
            // console.log(this.clusterObject)

            // this.$store.state.knowledgeNetworks.usersProgress.forEach(
            //     userdata => {
            //         console.log(userdata)
            //     },
            // )

            this.fitMap()
        },
        fitMap() {
            let southWest = L.latLng(
                    this.unVisibleMarkerGroup.getBounds()._southWest.lat,
                    this.unVisibleMarkerGroup.getBounds()._southWest.lng,
                ),
                northEast = L.latLng(
                    this.unVisibleMarkerGroup.getBounds()._northEast.lat,
                    this.unVisibleMarkerGroup.getBounds()._northEast.lng,
                ),
                bounds = L.latLngBounds(southWest, northEast)

            if (this.unVisibleMarkerGroup.getLayers().length === 1) {
                this.map.flyTo(this.center, this.zoom, {
                    animate: true,
                    duration: 5,
                })
            } else {
                // Fit the map with the visible markers bounds
                this.map.flyToBounds(bounds, {
                    animate: true,
                    duration: 5,
                    padding: [150, 750],
                    maxZoom: 18,
                })
            }
            this.clusterMarkers.refreshClusters()
            // console.log('refresh fitMap')
        },
        setClustering() {
            // if (this.clusterMarkers) {
            //     this.map.removeLayer(this.clusterMarkers)
            // }
            // console.log(this.clusterMarkers)

            this.clusterMarkers = L.markerClusterGroup({
                maxClusterRadius: 10, // this.clusterRadius,
                singleMarkerMode: true,
                iconCreateFunction: cluster => {
                    let markers = cluster.getAllChildMarkers()
                    let groups = {}
                    markers.forEach(marker => {
                        // console.log(m.options.userId)
                        if (
                            !groups[marker.options.userId] &&
                            marker.options.userId !== ''
                        ) {
                            groups[marker.options.userId] = []
                            groups[marker.options.userId] = marker
                        }
                    })
                    let pin =
                        '<div class="circle-marker circle-marker-' +
                        Object.keys(groups).length +
                        '" ><div class="colorWheel">'
                    for (var i = 0; i < Object.keys(groups).length; i++) {
                        // console.log(markers[i].options)

                        let singleMarkerStyle = ''
                        if (markers.length === 1) {
                            singleMarkerStyle =
                                ' ;border-color:' +
                                groups[Object.keys(groups)[i]].options.color
                        }
                        if (
                            groups[Object.keys(groups)[i]].options.last &&
                            groups[Object.keys(groups)[i]].options.userId > -1
                        ) {
                            pin +=
                                '<span class="color" style="border-top-color:' +
                                groups[Object.keys(groups)[i]].options.color +
                                singleMarkerStyle +
                                '"></span>'
                        }
                    }
                    pin += '</div></div>'
                    return L.divIcon({
                        html: pin,
                        className:
                            'mycluster circle-marker cluster-layer-' +
                            (Object.keys(groups).length + 9000),
                        name: 'delete',
                        iconSize: L.point(60, 60),
                    })
                },
                //Disable all of the defaults:
                spiderfyOnMaxZoom: false,
                showCoverageOnHover: false,
                zoomToBoundsOnClick: false,
            })

            // console.log(this.clusterMarkers)

            this.clusterMarkers.addLayers(this.markerGroup)
            // this.clusterMarkers.addLayer(this.markerStops)
            this.map.addLayer(this.clusterMarkers)
        },

        addLineToMap(start, end) {
            if (Math.abs(start[1] - end[1]) > 180.0) {
                const start_dist_to_antimeridian =
                    start[1] > 0 ? 180 - start[1] : 180 + start[1]
                const end_dist_to_antimeridian =
                    end[1] > 0 ? 180 - end[1] : 180 + end[1]
                const lat_difference = Math.abs(start[0] - end[0])
                const alpha_angle =
                    Math.atan(
                        lat_difference /
                            (start_dist_to_antimeridian +
                                end_dist_to_antimeridian),
                    ) *
                    (180 / Math.PI) *
                    (start[1] > 0 ? 1 : -1)
                const lat_diff_at_antimeridian =
                    Math.tan((alpha_angle * Math.PI) / 180) *
                    start_dist_to_antimeridian
                const intersection_lat = start[0] + lat_diff_at_antimeridian
                const first_line_end = [
                    intersection_lat,
                    start[1] > 0 ? 180 : -180,
                ]
                const second_line_start = [
                    intersection_lat,
                    end[1] > 0 ? 180 : -180,
                ]

                return [first_line_end, second_line_start]
                // return [start, first_line_end, second_line_start, end]
            } else {
                return false
            }
        },
        planeRoute(latlng1, latlng2, locationData) {
            let customPane = this.map.createPane('customPane')
            // let canvasRenderer = L.canvas({ pane: 'customPane' })
            let svgRenderer = L.svg({ pane: 'customPane' })
            // customPane.style.zIndex = 400
            // customPane.style.width = 10

            // customPane.style.transform = 'rotate(180deg);'

            let offsetX = latlng2[1] - latlng1[1],
                offsetY = latlng2[0] - latlng1[0]

            // offsetX += this.test
            // offsetY += this.test
            let r = Math.sqrt(Math.pow(offsetX, 2) + Math.pow(offsetY, 2)),
                theta = Math.atan2(offsetY, offsetX)

            let thetaOffset = 3.14 / 10

            let r2 = r / 2 / Math.cos(thetaOffset),
                theta2 = theta + thetaOffset
            //
            // var midpointX = r2 * Math.cos(theta2) + latlng1[1] - this.test,
            //     midpointY = r2 * Math.sin(theta2) + latlng1[0] + this.test / 3

            let midpointX = r2 * Math.cos(theta2) + latlng1[1],
                // this.curveLineCounter * 0.1,
                midpointY = r2 * Math.sin(theta2) + latlng1[0]
            // this.curveLineCounter * 0.6

            let progressData = this.$store.state.knowledgeNetworks.usersProgress.find(
                p => p.userId === 'knowledgeNetworks_' + locationData.userId,
            )
            let step = progressData.step

            let midpointLatLng = [midpointY, midpointX]
            this.curveLineCounter++
            let curve = L.curve(['M', latlng1, 'Q', midpointLatLng, latlng2], {
                // color: color,
                animate: 2000,

                name: locationData.userId + '-' + step,
                className: 'outlined plainline user-' + locationData.userId,
                // subRoute: subRoute,
                weight: 10,
                // renderer: svgRenderer,
                // renderer: canvasRenderer,
                // dashArray: '5, 10',
                // dashOffset: '2',
                fill: false,
                dashArray: '15',
                // lineCap: 'square',
                opacity: 0,
                userId: locationData.userId,
            }).addTo(this.map)

            let coords = curve.trace([
                0,
                0.05,
                0.1,
                0.15,
                0.2,
                0.25,
                0.3,
                0.35,
                0.4,
                0.45,
                0.5,
                0.55,
                0.6,
                0.65,
                0.7,
                0.75,
                0.8,
                0.85,
                0.9,
                0.95,
                1,
            ])

            let routeObject = []
            routeObject.push({ lineType: 'line', type: 'plane' })
            progressData = this.$store.state.knowledgeNetworks.usersProgress.find(
                p => p.userId === 'knowledgeNetworks_' + locationData.userId,
            )

            return coords
        },
        async carRoute(start, end, locationData) {
            //https://www.liedman.net/leaflet-routing-machine/tutorials/alternative-routers/
            let progressData = this.$store.state.knowledgeNetworks.usersProgress.find(
                p => p.userId === 'knowledgeNetworks_' + locationData.userId,
            )
            let step = progressData.step

            let route = L.Routing.control({
                waypoints: [L.latLng(start), L.latLng(end)],
                router: new L.Routing.osrmv1({
                    language: 'de',
                    profile: 'car',
                }),
                name: location.userId + '-' + step,
                show: false,
                fitSelectedRoutes: false,
                plan: false,
                opacity: 0,
                lineOptions: {
                    styles: [
                        {
                            opacity: 1,
                        },
                    ], // Adding animate class
                },
                // routeWhileDragging: true,
            }).addTo(this.map)

            let wayPoint1 = L.latLng(start)
            let wayPoint2 = L.latLng(end)

            let rWP1 = new L.Routing.Waypoint()
            rWP1.latLng = wayPoint1
            //
            let rWP2 = new L.Routing.Waypoint()
            rWP2.latLng = wayPoint2

            let myRoute = L.Routing.osrmv1()

            await myRoute.route([rWP1, rWP2], (err, routes) => {
                this.routeHolder = {
                    route: routes[0].coordinates,
                    user: locationData,
                }
            })
        },
        addTargetMarker(pointStart, pointEnd, locationData) {
            let counter = 1
            let progressData = this.$store.state.knowledgeNetworks.usersProgress.find(
                p => p.userId === 'knowledgeNetworks_' + locationData.userId,
            )
            let step = progressData.step

            let endMarker = L.marker(pointEnd, {
                icon: new L.DivIcon({
                    className: 'group-div-icon',
                    // :style="@include on-circle($item-count:3, $circle-size: 20em, $item-size: 6em);}"
                    html:
                        '<div :class="circle-marker circle-marker-' +
                        (counter + 1) +
                        '" >' +
                        '</div>',
                }),
                last: true,
                remove: true,
                // subRoute: subRoute,
                name: locationData.userId + '-' + step,
                clusterKey: locationData.pid,
                pid: locationData.pid + '_step-' + locationData.position,
                color: this.colors[locationData.userId],
                userId: locationData.userId,
            })
            //.addTo(this.map)
            this.markerGroup.addLayer(endMarker)
            this.unVisibleMarkerGroup.addLayer(endMarker)
            this.clusterMarkers.addLayer(endMarker)
            this.clusterMarkers.refreshClusters()
            // console.log('refreshClusters 8')
        },
        getUserOffset(userId) {
            let user = this.usersData.find(x => x.user === userId)
            if (user) {
                return user.position
            }
            return 0
        },
        diff(arr1, arr2) {
            let newArr = []
            newArr = arr1
                .filter(function(v) {
                    return arr2.indexOf(v) < 0
                })
                .concat(
                    arr2.filter(function(v) {
                        return arr1.indexOf(v) < 0
                    }),
                )

            return newArr
        },
    },
}
</script>

<style lang="scss">
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

#mapContainer {
    width: 100vw;
    height: 100vh;
    z-index: 1;
}

.museum {
    z-index: 999999 !important;
}

/* Marker-Group styles start */
.grouped-div-image {
    float: left !important;
    width: calc(120vw * var(--pxToVw)) !important;
    height: calc(120vw * var(--pxToVw)) !important;
    display: block;
    position: relative;
}

$circle: calc(60vw * var(--pxToVw));

.grouped-div-icon .circle-marker {
    display: none;
}

.grouped-div-icon .circle-marker-1 {
    display: block;
}

.marker-stop,
.mycluster {
    width: $circle;
    height: $circle;
    //margin: calc($circle * -1) !important;

    .circle-marker {
        display: block;
    }
}

.circle-marker {
    width: $circle !important;
    height: $circle !important;
    z-index: 9;
    //left: calc(-1 * ($circle / 2));
    //top: calc(-1 * ($circle / 2));
    //transform: translateX(-50%) translateY(-50%);
}

.marker-stop {
    width: $circle !important;
    height: $circle !important;
    //left: calc(-1 * ($circle / 2));
    //top: calc(-1 * ($circle / 2));
    z-index: 9;
}

/* Marker-Group styles end */

/* car Waypoint start */
path.carline.line-0 {
    transform: translateY(0px) translateX(0px);
    transform-origin: left top;
    z-index: 100 !important;
}

path.carline.line-1 {
    transform: translateY(6px) translateX(6px);
    transform-origin: left top;
    z-index: 90 !important;
}

path.carline.line-2 {
    transform: translateY(12px) translateX(12px);
    transform-origin: left top;
    z-index: 80;
}

path.carline.line-3 {
    transform: translateY(18px) translateX(18px);
    z-index: 70;
}

path.carline.line-4 {
    transform: translateY(24px) translateX(24px);
    z-index: 60;
}

path.carline.line-5 {
    transform: translateY(30px) translateX(30px);
    z-index: 50;
}

/* car Waypoint end */

/* Screen lines start */
.knowledge-networks-container {
    height: 100vh;

    .line {
        position: fixed;
        z-index: 999;
        height: 100vh;
        width: calc(10vw * var(--pxToVw));
        background-color: black;

        &.line-1 {
            left: 33.33%;
        }

        &.line-2 {
            right: 33.33%;
        }
    }
}

/* Screen lines end */
.leaflet-control-container .leaflet-routing-container-hide {
    display: none;
}

.leaflet-interactive,
path.leaflet-interactive.outlined {
    z-index: 100;
    filter: drop-shadow(-1px -1px 0px #fff) drop-shadow(1px -1px 0px #fff)
        drop-shadow(1px 1px 0px #fff) drop-shadow(-1px 1px 0px #fff);
}

.leaflet-interactive {
    //margin: calc(-1 * $circle) !important;
}

.preview-stop {
    width: 1vw;
    height: 1vw;
    background-color: white;
    border: 2px solid black;
    border-radius: 50%;
}

.museum {
    //top: 100px;
    //left: -20px;
    top: 80px;
    left: 20px;
    z-index: 1;
}

svg,
path,
g {
    z-index: 1;
}

.mycluster .grouped-div-icon {
    display: block;
}

.grouped-div-icon.animating {
    animation: fadein 2s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.leaflet-div-icon {
    background: transparent;
    border: none;
}

.leaflet-div-icon1 {
    width: calc(100vw * var(--pxToVw)) !important;
    height: calc(100vw * var(--pxToVw)) !important;
    z-index: 999999;
    border: calc(3vw * var(--pxToVw)) solid black !important;
    border-radius: calc(75vw * var(--pxToVw)) !important;
    margin-left: calc(-75vw * var(--pxToVw)) !important;
    margin-top: calc(-75vw * var(--pxToVw)) !important;
    //transform: translateX(-50%) translateY(-50%) !important;
}

.motion-icon {
    top: -50%;
    left: -50%;
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    z-index: 99;

    i {
        transform-origin: center;
    }
}

.leaflet-pane {
    z-index: unset;
}

.leaflet-norotate-pane {
    z-index: 900;
}

.leaflet-overlay-pane {
    display: none;
}

.leaflet-marker-draggable {
    display: none;
}

.colorWheel {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 100%;
    overflow: hidden;
    z-index: 9999;
}

.colorWheel:after {
    content: '';
    position: absolute;
    border-radius: 50%;
    background: #fff;
    top: calc(20vw * var(--pxToVw));
    left: calc(20vw * var(--pxToVw));
    right: calc(20vw * var(--pxToVw));
    bottom: calc(20vw * var(--pxToVw));
}

.colorWheel span {
    position: absolute;
    border-style: solid;
    border-width: calc(150vw * var(--pxToVw)) calc(5000vw * var(--pxToVw)); /*adjust the 36px to control the distance*/
    left: 50%;
    top: 50%;
    color: transparent;
    width: 2rem;
    height: 2rem;
    //color: transparent;
    transform: translateX(-50%) translateY(-50%);
}

$user-1-color: #004f8d;
$user-2-color: #910114;
$user-3-color: #0a7502;
$user-4-color: #df6800;
$user-5-color: #f00187;
$user-6-color: #01213f;

.circle-marker-1 {
    span:nth-child(1) {
        border-width: 3000px;
        left: 50%;
        top: 50%;
    }
}

.circle-marker-2 {
    $num: 2;
    @for $i from 1 through $num {
        span:nth-child(#{$i}) {
            transform: translate(-50%, -50%) rotate($i * (360deg / $num));
        }
    }
}

.circle-marker-3 {
    $num: 3;
    @for $i from 1 through $num {
        span:nth-child(#{$i}) {
            transform: translate(-50%, -50%) rotate($i * (360deg / $num));
            border-width: 150px 360px;
        }
    }
}

.circle-marker-4 {
    $num: 4;
    @for $i from 1 through $num {
        span:nth-child(#{$i}) {
            transform: translate(-50%, -50%) rotate($i * (360deg / $num));
            border-width: 150px 180px;
        }
    }
}

.circle-marker-5 {
    $num: 5;
    @for $i from 1 through $num {
        span:nth-child(#{$i}) {
            transform: translate(-50%, -50%) rotate($i * (360deg / $num));
            border-width: 150px (540px / $num);
        }
    }
}

.circle-marker-6 {
    $num: 6;
    @for $i from 1 through $num {
        span:nth-child(#{$i}) {
            transform: translate(-50%, -50%) rotate($i * (360deg / $num));
            border-width: 150px (540px / $num);
        }
    }
}

.mycluster .circle-marker > .colorWheel {
    //transform: none !important;
    margin: unset !important;
}

.circle-marker > .colorWheel {
    margin: 0;
}

video {
    z-index: 999999;
    position: absolute;
    width: auto;
    height: auto;
    top: 0;
}

#knowledgeNetwork.vh-100 {
    height: 100vh !important;
}

.unvisible {
    width: calc(20vw * var(--pxToVw));
    height: calc(20vw * var(--pxToVw));
    //background: black;
    z-index: 99999;
    //display: none;
    transform: translateX(-50%) translateY(-50%);
}

.animated-marker {
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    height: $circle;
    width: $circle;
    transform: scale(1);
    animation: pulse-black 2s infinite;
}

@keyframes pulse-black {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.hiddenline {
    transition: all 0.3s linear;
    opacity: 0;
}

.leaflet-pane.leaflet-polymotion-pane {
    svg {
        position: absolute;
    }
}

.leaflet-marker-pane > img {
    display: none;
}

.leaflet-marker-pane > img.museum {
    display: block;
}

.cluster-layer-9001 {
    z-index: 9001 !important;
}

.cluster-layer-9002 {
    z-index: 9002 !important;
}

.cluster-layer-9003 {
    z-index: 9003 !important;
}

.cluster-layer-9004 {
    z-index: 9004 !important;
}

.cluster-layer-9005 {
    z-index: 9005 !important;
}

.cluster-layer-9006 {
    z-index: 9006 !important;
}
</style>
