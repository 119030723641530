<script>
import api from '../../../mixins/api'

export default {
    name: 'UserLogin',
    props: {
        connectedUsers: {
            type: Array,
            default: null,
        },
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            openObj: [],
            timeoutObj: [],
            idle: 10000,
            idle2: 20000,
            pulse: true,
            playNowObj: [],
            pulseInterval: null,
        }
    },
    mounted() {
        this.pulseInterval = setInterval(() => {
            this.pulse = !this.pulse
        }, this.idle)
    },
    methods: {
        open(i) {
            if (this.openObj.includes(i)) {
                let index = this.openObj.indexOf(i)

                this.openObj.splice(index, 1)

                clearTimeout(this.timeoutObj[i])
            } else {
                this.openObj.push(i)
                this.timeoutObj[i] = setTimeout(() => {
                    this.open(i)
                }, this.idle2)
            }
            console.log(this.openObj)
        },

        loginWithoutMobile(id) {
            const unit = 'knowledgeNetworks_' + id
            const createCollectionFor = 'knowledgeNetworks'

            this.playNowObj.push(id)

            setTimeout(() => {
                let index = this.playNowObj.indexOf(id)

                this.playNowObj.splice(index, 1)
            }, 3000)
            api.twoavy.loginUserWithoutMobile(unit, createCollectionFor)
        },
    },
}
</script>
<template>
    <div
        class="bgbox"
        :class="['bgbox-' + id, openObj.includes(id) ? 'open' : '']"
        @click="open(id)"
    >
        <div class="toggle" :class="pulse ? 'visible' : ''">
            <div
                class="absolute sonar"
                :class="
                    openObj.includes(id) || !pulse || playNowObj.includes(id)
                        ? 'hide'
                        : ''
                "
            >
                <span
                    class="cbutton cbutton--effect-ivana cbutton--click"
                ></span>
            </div>
            <img
                :class="
                    openObj.includes(id) || !pulse || playNowObj.includes(id)
                        ? 'hide'
                        : ''
                "
                class="btn cbutton cbutton--effect-ivana cbutton--click"
                :src="require('@/assets/img/hand.svg')"
                alt=""
            />
            <img
                v-if="openObj.includes(id)"
                class="btn-close"
                :src="require('@/assets/img/close.svg')"
                alt=""
            />
        </div>
        <div v-if="openObj.includes(id)" class="box-content flex flex-col">
            <div class="box-header">
                <h1>Download</h1>
            </div>
            <div class="score-wrapper w-100">
                <div class="store w-50">
                    <h2>Android</h2>
                    <img
                        class="w-50"
                        :src="require('@/assets/img/qr_code_android.png')"
                        alt=""
                    />
                </div>
                <div class="store w-50">
                    <h2>iOS</h2>
                    <img
                        class="w-50"
                        :src="require('@/assets/img/qr_code_ios.png')"
                        alt=""
                    />
                </div>

                <div class="play-now">
                    <h2
                        v-if="
                            !connectedUsers.includes('knowledgeNetworks_' + id)
                        "
                        @click="loginWithoutMobile(id)"
                    >
                        <b>jetzt spielen</b>
                        <br />
                        play now
                    </h2>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.box-header {
    width: 60%;
    justify-content: center;
    align-items: center;
    text-align: center;

    h1 {
        color: white;
        padding-bottom: 5px;
        padding-top: 5px;
    }
}

.btn-close {
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin: 20px;
    position: absolute;
}

.bgbox {
    height: 10vh;
    width: 25vh;
    display: flex;
    align-content: center;
    align-items: center;
    position: relative;
}

.box-content h2,
.box-content p {
    color: white;
}

$user-1-color: #004f8d; //#188ad9;
$user-2-color: #910114;
$user-3-color: #0a7502;
$user-4-color: #df6800;
$user-5-color: #f00187;
$user-6-color: #01213f;

.bgbox-1,
.bgbox-2,
.bgbox-3 {
    transform: translateY(5vh);
    position: absolute;
    transition: all 0.3s linear;
    left: 50%;
    padding-top: 8vh;

    &.open {
        transform: translateY(0vh);
        border: 2px solid;

        padding-top: 1vh;
        background-color: rgba(0, 0, 0, 0.5);
    }

    img.btn-close,
    img.btn {
        position: absolute;
        top: 10px;
        right: 0px;
    }

    .sonar {
        position: absolute;
        top: 15px;
        right: 140px;
    }
}

.bgbox-4,
.bgbox-5,
.bgbox-6 {
    transform: translateY(-5vh);
    position: absolute;
    transition: all 0.3s linear;
    padding-bottom: 8vh;

    &.open {
        transform: translateY(0vh);
        border: 2px solid;

        padding-bottom: 0vh;
        background-color: rgba(0, 0, 0, 0.5);
    }

    img.btn-close,
    img.btn {
        position: absolute;
        bottom: 10px;
        transform: rotate(180deg) translateX(-50%);
        left: 0px;
        transform-origin: center center;
    }

    .box-content {
        position: absolute;
        bottom: -50px;
        transform: rotate(180deg) translateX(0%) translateY(50%);
        left: 0px;
        transform-origin: center center;
    }

    .sonar {
        position: absolute;
        bottom: 15px;
        left: 220px;
    }
}

.sonar,
.toggle .btn {
    //animation: fadeinout 10s linear infinite;
    opacity: 1;
}

//@keyframes fadeinout {
//    0% {
//        opacity: 1;
//    }
//    15% {
//        opacity: 1;
//    }
//    25% {
//        opacity: 1;
//    }
//    75% {
//        opacity: 1;
//    }
//    85% {
//        opacity: 1;
//    }
//    100% {
//        opacity: 1;
//    }
//}

//.bgbox-1.open {
//    border-color: $user-1-color;
//}
//
//.bgbox-2.open {
//    border-color: $user-2-color;
//}
//
//.bgbox-3.open {
//    border-color: $user-3-color;
//}
//
//.bgbox-4.open {
//    border-color: $user-4-color;
//}
//
//.bgbox-5.open {
//    border-color: $user-5-color;
//}
//
//.bgbox-6.open {
//    border-color: $user-6-color;
//}

.score-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.score-wrapper .store {
    display: flex;
    flex-direction: column;
    align-items: center;

    border-right: 3px solid black;
}

.play-now {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    text-align: center;
}
.play-now h2 {
    border: 2px solid white;
    padding: 20px;
}

/* Ivana */

.cbutton {
    position: relative;
    display: inline-block;
    margin: 1em;
    padding: 0;
    border: none;
    background: none;
    //color: #286aab;
    color: #000;
    font-size: 1.4em;
    overflow: visible;
    -webkit-transition: color 0.7s;
    transition: color 0.7s;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.cbutton.cbutton--click,
.cbutton:focus {
    outline: none;
    //color: #3c8ddc;
    color: rgba(0, 0, 0, 0.5);
}

.cbutton--effect-ivana::before {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -70px 0 0 -70px;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    content: '';
    opacity: 0;
    pointer-events: none;
}

.cbutton::after {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -35px 0 0 -35px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    content: '';
    opacity: 0;
    pointer-events: none;
}

.cbutton--effect-ivana::before,
.cbutton--effect-ivana::after {
    box-shadow: 0 0 0 20px rgba(111, 148, 182, 0.5);
}

.cbutton--effect-ivana.cbutton--click::before {
    animation: anim-effect-ivana-1 1.5s infinite;
}

.cbutton--effect-ivana.cbutton--click::after {
    animation: anim-effect-ivana-2 1.5s infinite;
}

@keyframes anim-effect-ivana-1 {
    0% {
        opacity: 1;
        transform: scale3d(0.5, 0.5, 1);
    }
    100% {
        opacity: 0;
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes anim-effect-ivana-2 {
    0% {
        opacity: 1;
        transform: scale3d(0.5, 0.5, 1);
    }
    50%,
    100% {
        opacity: 0;
        transform: scale3d(1.2, 1.2, 1);
    }
}

@keyframes fade-in-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

.cbutton--click {
    opacity: 1;
    transition: opacity 1s linear;
}
.sonar.visible,
.cbutton--click.visible {
    opacity: 1;
    transition: opacity 1s linear;
}
.sonar.hide,
.cbutton--click.hide {
    opacity: 0;
    transition: opacity 0.1s linear;
}
</style>
