<template>
    <div
        v-if="connectedUsers.includes('knowledgeNetworks_' + id)"
        :ref="'userbox-' + id"
        class="user"
        :class="'userbox-' + id"
    >
        <LoginTimer
            v-if="currentUser"
            :key="'login-timer-knowledgeNetworks_' + id"
            :unit="currentUser.unit"
            :user-uuid="currentUser.uuid"
            :active-area="$refs['userbox-' + id]"
        />
        <div
            class="collected-items-container"
            :class="{ visible: userdataIdle }"
        >
            <div class="collected-items-container-inner">
                <div class="title">
                    <h1>
                        <span>
                            Forum Wissen
                        </span>
                    </h1>
                </div>
                <!--            :key="'item-' + i"-->

                <!--              matter-->
                <!--                <div :ref="'matter-' + id" class="matter">-->
                <!--                    <div class="matter-inner">-->
                <!--                        <span-->
                <!--                            v-for="(item, i) in itemsObj"-->
                <!--                            :key="item.id"-->
                <!--                            class="item"-->
                <!--                            :style="getItemStyle(item)"-->
                <!--                            @click="setItem(item, i)"-->
                <!--                        ></span>-->
                <!--                    </div>-->
                <!--                </div>-->
                <!--              matter-->

                <div v-if="itemsObj.length" ref="matter" class="user-element">
                    <div class="user-element-inner">
                        <span
                            v-for="(item, i) in itemsObj"
                            :key="item.id"
                            class="item"
                            :style="getItemStyle(item, i)"
                            @click="setItem(item, i)"
                        ></span>
                    </div>
                </div>
                <div v-else class="no-collected-items">
                    <h1
                        class="text-black-50"
                        v-html="
                            $store.getters['interfaceTexts/getInterfaceText'](
                                'collect_items',
                            )[lang]
                        "
                    ></h1>
                </div>

                <div
                    class="logout-btn m-2"
                    @click="logoutFromUnit('knowledgeNetworks_' + id, id)"
                >
                    <i class="fa fa-sign-out"></i>
                </div>
            </div>
        </div>
        <div
            class="current-item"
            :class="selectedItemId > -1 ? 'fadeInOut' : ''"
        >
            <div class="flickity-container">
                <div class="flickity-container-inner">
                    <div v-if="selectedItemId < 0" class="user-coin">
                        <span>{{ id }}</span>
                    </div>
                    <div v-if="selectedItemId > -1" class="right-navi">
                        <div class="close-btn m-2" @click="closeInfo(id - 1)">
                            <svg
                                class="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="4"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </div>
                    </div>

                    <div v-if="selectedItemObj" class="title">
                        <h1>
                            <span
                                v-if="
                                    selectedItemObj.name &&
                                        selectedItemObj.name[lang]
                                "
                            >
                                {{ selectedItemObj.name[lang] }}
                            </span>
                            <span v-else-if="selectedItemObj.name.de">
                                {{ selectedItemObj.name.de }}
                            </span>
                            <span v-else>
                                {{ selectedItemObj.id }}
                            </span>
                        </h1>
                    </div>
                    <div class="slider-navigation w-100">
                        <div
                            v-if="slideIndex > -1"
                            class="w-100 slider-navigation-wrap text-left"
                        >
                            <button
                                class="prev navi-btn"
                                :disabled="slideIndex === 0 || startCounter"
                                :class="{
                                    disabled: slideIndex === 0 || startCounter,
                                }"
                                @click="setLocation('prev')"
                            >
                                <font-awesome-icon
                                    :icon="['far', 'angle-left']"
                                    size="sm"
                                />
                            </button>
                            <button
                                class="next navi-btn"
                                :disabled="
                                    slideIndex === slider.length - 1 ||
                                        startCounter
                                "
                                :class="{
                                    disabled:
                                        slideIndex === slider.length - 1 ||
                                        startCounter,
                                }"
                                @click="setLocation('next')"
                            >
                                <font-awesome-icon
                                    :icon="['far', 'angle-right']"
                                    size="sm"
                                />
                            </button>
                        </div>
                    </div>

                    <div class="progress-container">
                        <div class="progress">
                            <div class="percent" style="width:30%"></div>
                        </div>
                        <div class="steps">
                            <div
                                v-for="(slide, i) in slider"
                                :id="i"
                                :key="'slide-dot-' + i"
                                :class="i <= slideIndex ? 'completed' : ''"
                                class="step"
                            ></div>
                        </div>
                    </div>
                    <div
                        v-if="startCounter || startCounterStoped"
                        class="round-time-bar"
                        data-style="smooth"
                        style="--duration:5;"
                    >
                        <div></div>
                    </div>
                    <div
                        v-if="slider"
                        ref="flickity"
                        class="flickity"
                        @click="activateUser()"
                    >
                        <div
                            v-for="(slideInfo, index) in slider"
                            :key="'cell_' + index"
                            class="carousel-cell"
                        >
                            <div class="cell-inner image">
                                <div
                                    v-if="
                                        slideInfo.content &&
                                            slideInfo.content.image &&
                                            slideInfo.content.image.global
                                                .imageAsset &&
                                            slideInfo.content.image.global
                                                .imageAsset.paths &&
                                            slideInfo.content.image.global
                                                .imageAsset.paths.url
                                    "
                                    class="image-container"
                                >
                                    <img
                                        :src="
                                            slideInfo.content.image.global
                                                .imageAsset.paths.url
                                        "
                                        alt=""
                                    />
                                    <div
                                        class="copyright"
                                        :class="{ visible: assetInfo }"
                                    >
                                        <font-awesome-icon
                                            v-if="
                                                slideInfo.content &&
                                                    slideInfo.content.copyright
                                            "
                                            class="copyright-btn"
                                            :icon="['far', 'info-circle']"
                                            size="lg"
                                            @click="toggleInfo()"
                                        />
                                        <div class="copyright-body">
                                            <p
                                                v-if="
                                                    slideInfo.content &&
                                                        slideInfo.content
                                                            .copyright &&
                                                        slideInfo.content
                                                            .copyright
                                                "
                                                v-html="
                                                    slideInfo.content.copyright[
                                                        lang
                                                    ]
                                                "
                                            ></p>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-else-if="
                                        slideInfo.content &&
                                            slideInfo.content.quote &&
                                            slideInfo.content.quote[lang]
                                    "
                                >
                                    <div
                                        v-html="slideInfo.content.quote[lang]"
                                    ></div>
                                </div>
                            </div>

                            <div
                                v-if="
                                    slideInfo.content && slideInfo.content.text
                                "
                                class="cell-inner text mr-5"
                            >
                                <h2
                                    v-if="
                                        slideInfo.content &&
                                            slideInfo.content.aspect
                                    "
                                    v-html="slideInfo.content.aspect[lang]"
                                ></h2>
                                <p
                                    v-if="
                                        slideInfo.content.place_time &&
                                            slideInfo.content.place_time[lang]
                                    "
                                >
                                    {{ slideInfo.content.place_time[lang] }}
                                </p>
                                <p
                                    v-else-if="
                                        slideInfo.content.place_time &&
                                            slideInfo.content.place_time.de
                                    "
                                >
                                    {{ slideInfo.content.place_time.de }}
                                </p>
                                <p></p>
                                <p
                                    v-if="slideInfo.content.text[lang]"
                                    v-html="slideInfo.content.text[lang]"
                                ></p>
                                <p
                                    v-else-if="slideInfo.content.text.de"
                                    v-html="slideInfo.content.text.de"
                                ></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Flickity from 'flickity'
import api from '../../../mixins/api'
//import UnloadAnimation from '../../../utils/UnloadAnimation'
import { nestedSet } from '@twoavy/twoavy-nested-set'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import LoginTimer from '@/components/global/LoginTimer.vue'

export default {
    name: 'User',
    components: { FontAwesomeIcon, LoginTimer },
    props: {
        id: {
            type: Number,
            default: null,
        },
        activeUsers: {
            type: Array,
            default: null,
        },
        connectedUsers: {
            type: Array,
            default: null,
        },
        userdata: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            flickity: null,
            slideIndex: 0,
            selectedItemId: -1,
            selectedItem: null,
            selectedItemObj: null,
            flickityOptions: {
                prevNextButtons: false,
                pageDots: true,
                draggable: false,
                cellAlign: 'left',
                fade: true,
            },
            points: [],
            slider: [],
            // colors: ['red', 'blue', 'green', 'yellow', 'black', 'pink'],
            itemsObj: [],
            pointData: [],
            userdataIdle: false,
            positions: {},
            assetInfo: false,
            unloadAnimation: null,
            circleCoordinate: [],
            startCounter: false,
            startCounterStoped: false,
            slideChangeDelay: 5000,
            checkUserInterval: null,
            currentUser: null,
            ref: null,
        }
    },
    computed: {
        lang() {
            return this.$store.getters.getUserLanguage(
                'knowledgeNetworks_' + this.id,
            )
        },
    },
    watch: {
        selectedItemId(value) {
            if (value === -1) {
                this.flickity.destroy()
            } else {
                this.$nextTick(() => {
                    this.flickity = new Flickity(
                        this.$refs.flickity,
                        this.flickityOptions,
                    )

                    this.flickity.reloadCells()
                    setTimeout(() => {
                        this.flickity.select(0)
                        this.slideIndex = 0
                    }, 500)
                })
            }
        },
    },

    beforeMount() {
        this.filterUserItems('knowledgeNetworks_' + this.id)
        setTimeout(() => {
            // this.unloadAnimation.start()
        }, 2000)
    },
    async mounted() {
        setTimeout(() => {
            this.userdataIdle = true
        }, 100)

        this.$nextTick(() => {
            this.setRandomItemPosition()
        })

        this.currentUser = this.$store.getters.getUserAtUnit(
            'knowledgeNetworks_' + this.id,
        )
    },
    methods: {
        setRandomItemPosition() {
            let circles = []
            let protection = 0

            function getDistance(pointAX, pointAY, pointBX, pointBY) {
                let dx = pointBX - pointAX
                let dy = pointBY - pointAY

                let dist = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2))

                return dist
            }

            let zoneWrapper = document.querySelector('.user-element')
            let zone = document.querySelector('.user-element-inner')
            let diametre = 150
            let canvasWidth = 1400 - diametre
            let canvasHeight = 450 - diametre
            while (circles.length < this.itemsObj.length) {
                // for (var i = 0; i < 25; i++) {
                // for (var i = 0; i < 25; i++) {
                let x = Math.round(Math.random() * canvasWidth)
                let y = Math.round(Math.random() * canvasHeight)
                let circle = {
                    x: x,
                    y: y,
                    r: diametre / 2,
                }

                let overlapping = false
                for (let j = 0; j < circles.length; j++) {
                    let other = circles[j]
                    let d = getDistance(circle.x, circle.y, other.x, other.y)
                    // console.log(d)
                    if (d < circle.r + other.r + 1) {
                        overlapping = true
                        break
                    }
                }
                if (!overlapping) {
                    circles.push(circle)
                    // console.log(circle)
                }

                protection++
                if (protection > 1000) {
                    break
                }
            }

            this.circleCoordinate = circles
        },
        toggleInfo() {
            this.assetInfo = !this.assetInfo

            const user = this.$store.getters.getUserAtUnit(
                'knowledgeNetworks_' + this.id,
            )
            //api.twoavy.stayAtUnit(user.uuid, user.unit)
        },
        getItemStyle(item, i) {
            // const position = this.positions[item.id]

            let bgImage = ''
            if (item.thumbnailUrl !== undefined) {
                bgImage = "url('" + item.thumbnailUrl + "')"
            } else {
                bgImage =
                    "url('" +
                    this.$store.getters.getSetting('collection_base_url') +
                    '/api/v1/records/record_kuniweb_1109710/files/images/record_kuniweb_1109710_468944.jpg/full/!400,400/0/default.jpg'
            }

            let circleCoordinate = this.circleCoordinate[i]
            if (circleCoordinate) {
                // TODO: smarter fallback, draw offscreen for now
                return {
                    position: 'absolute',
                    zIndex: 99,
                    backgroundImage: bgImage,
                    opacity: 1,
                    left: this.circleCoordinate[i].x + 'px',
                    top: this.circleCoordinate[i].y + 'px',
                }
            }
        },
        getImage(item) {
            if (item.thumbnailUrl) {
                return item.thumbnailUrl
            } else {
                return (
                    this.$store.getters.getSetting('collection_base_url') +
                    '/api/v1/records/record_kuniweb_1109710/files/images/record_kuniweb_1109710_468944.jpg/full/!400,400/0/default.jpg'
                )
            }
        },
        getBgImage(item) {
            // console.log(item)
            if (item.thumbnailUrl) {
                return "background-image: url('" + item.thumbnailUrl + "');"
            } else {
                return (
                    "background-image: url('" +
                    this.$store.getters.getSetting('collection_base_url') +
                    "/api/v1/records/record_kuniweb_1109710/files/images/record_kuniweb_1109710_468944.jpg/full/!400,400/0/default.jpg');"
                )
            }
        },
        closeInfo(userId) {
            this.selectedItemId = -1
            this.$emit('close-info', userId)

            this.selectedItem = null
            this.selectedItemObj = null
            this.startCounter = false
            this.slider = []
        },

        logoutFromUnit(userId, index) {
            this.closeInfo(index)
            api.twoavy.logoutFromUnit(userId)
        },
        async getItemData(pid) {
            this.pointData = this.itemsObj.find(item => item.id === pid)

            this.slider = this.pointData.points.filter(
                s =>
                    s.content &&
                    s.content.fly_through &&
                    s.content.fly_through.global !== 'true',
            )
            this.slider = this.pointData.points
        },
        async filterUserItems() {
            let filteredNetworkElements = []
            this.$store.state.content.forEach(el => {
                if (
                    el.content.pid &&
                    this.collectedItems('knowledgeNetworks_' + this.id).find(
                        x => x.id === el.content.pid.global,
                    )
                ) {
                    filteredNetworkElements.push(el)
                }
            })

            filteredNetworkElements.forEach((el, index) => {
                this.itemsObj[index] = this.collectedItems(
                    'knowledgeNetworks_' + this.id,
                ).find(item => item.id === el.content.pid.global)
                this.itemsObj[index].points = nestedSet
                    .childrenById(this.$store.state.content, el.id)
                    .filter(point => point.published === true)

                this.itemsObj[index].name = el.content.name
                    ? el.content.name
                    : ''

                this.itemsObj[index].points.forEach((el, i) => {
                    this.itemsObj[index].points[
                        i
                    ].subpoints = nestedSet
                        .childrenById(this.$store.state.content, el.id)
                        .filter(point => point.published === true)
                })
            })
        },

        setItem(item, i) {
            this.selectedItem = item.id
            this.selectedItemObj = item
            this.getItemData(this.selectedItem)
            this.selectedItemId = i

            this.$emit('select-item', {
                userId: this.id - 1,
                points: this.pointData.points,
                position: 0,
                pid: this.selectedItem,
                direction: 'direction',
            })

            this.startCounter = false
            this.startCounter = true
            setTimeout(() => {
                this.startCounter = false
                this.startCounterStoped = true
            }, this.slideChangeDelay)

            const user = this.$store.getters.getUserAtUnit(
                'knowledgeNetworks_' + this.id,
            )
            //api.twoavy.stayAtUnit(user.uuid, user.unit)
        },
        collectedItems(userIndex) {
            let items = this.$store.getters
                .getUserAtUnit(userIndex)
                ?.collection.filter(x => x.nfcItem)
            return items
        },
        activateUser() {
            this.$emit('activate-user', this.id)
        },

        setLocation(direction) {
            if (direction === 'next') {
                this.flickity.next()
                this.slideIndex++
            } else {
                this.flickity.previous()
                this.slideIndex--
            }
            this.$emit('location', {
                userId: this.id - 1,
                points: this.pointData.points,
                position: this.flickity.selectedIndex,
                pid: this.selectedItem,
                direction: direction,
            })
            const user = this.$store.getters.getUserAtUnit(
                'knowledgeNetworks_' + this.id,
            )
            //api.twoavy.stayAtUnit(user.uuid, user.unit)

            this.assetInfo = false
            this.startCounterStoped = false
            setTimeout(() => {
                this.startCounter = true
            }, 100)
            setTimeout(() => {
                this.startCounter = false
                this.startCounterStoped = true
            }, this.slideChangeDelay)
        },
    },
}
</script>

<style lang="scss">
.user {
    width: calc(100% / 3);
    height: calc(100% / 2);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 10;
    //border: solid 1px red;
    position: absolute;

    .collected-items-container-inner {
    }

    .collected-items-container {
        width: 70%;
        height: 30%;
        position: relative;
        background-color: rgba(255, 255, 255, 1);
        padding: 1vw 3vw;
    }

    .item {
        position: relative;
        width: 2.2vw;
        height: 2.2vw;
        display: inline-block;
        margin: 0.5vw;
        z-index: 21;
        border-radius: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .flickity-container {
        width: 70%;
        height: 30%;
        border-style: none solid solid solid;
        border-width: calc(12vw * var(--pxToVw));
        color: white;
        background-color: rgba(255, 255, 255, 1);
        opacity: 1;

        position: relative;
        outline: none;

        .flickity-container-inner {
            padding: calc(10vw * var(--pxToVw));
            overflow: hidden;
            width: 100%;
            height: 100%;
        }

        .item {
            position: absolute;
            left: 0px;
            bottom: 0px;
            margin: 0px;
            width: 1.5vw;
            height: 1.5vw;
        }

        .carousel-cell {
            width: 100%;
            display: flex;
            margin-right: calc(30vw * var(--pxToVw));
            outline: none;

            .cell-inner {
                width: 70%;
                outline: none;

                .image-container {
                    background-color: black;
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    position: relative;
                }

                &.image {
                    max-width: 30%;
                    padding-top: 1vw;

                    img {
                        max-width: 100%;
                        object-fit: contain;
                    }
                }

                &.text {
                    margin-top: 1vw;
                    margin-left: 1vw;
                    color: #000;
                    overflow: auto;
                }

                .item {
                    bottom: 10vw;
                    position: relative;
                    width: 100%;
                    max-width: 30%;
                    height: auto;
                    max-height: 60%;
                }
            }
        }
    }
}

.slider-navigation {
    width: 100%;
    left: 0px;
    position: absolute;
    height: auto;
    z-index: 9999999;
    bottom: 50%;
    text-align: center;

    .slider-navigation-wrap {
        position: relative;
    }

    button {
        appearance: none;
        border: none;
        background-color: white;
        width: auto;
        height: calc(80vw * var(--pxToVw));

        &.navi-btn {
            position: absolute;
            width: calc(80vw * var(--pxToVw));
            height: calc(80vw * var(--pxToVw));

            border: calc(3vw * var(--pxToVw)) solid var(--semilightgrey);
            bottom: calc(-30vw * var(--pxToVw));

            &.prev {
                left: calc(-100vw * var(--pxToVw));
            }

            &.next {
                right: calc(-100vw * var(--pxToVw));
            }
        }

        font-size: calc(80vw * var(--pxToVw));
        position: relative;
        margin: 0 calc(15vw * var(--pxToVw));

        img {
            width: auto;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            position: absolute;
            height: calc(80vw * var(--pxToVw));
        }
    }
}

.user-coin {
    span {
        width: 100%;
        height: 100%;
        display: inline-block;
        font-weight: bold;
    }

    text-align: center;
    position: absolute;
    z-index: 999;
    bottom: calc(3vw * var(--pxToVw));
    right: calc(8vw * var(--pxToVw));
    width: calc(20vw * var(--pxToVw));
    height: calc(20vw * var(--pxToVw));
}

.corner {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 10;
    width: 0;
    height: 0;
    border-right: 3vw solid transparent;
}

$user-1-color: #004f8d; //#188ad9;
$user-2-color: #910114;
$user-3-color: #0a7502;
$user-4-color: #df6800;
$user-5-color: #f00187;
$user-6-color: #01213f;
//
$user-1-border-color: #004f8d; //##188ad9;
$user-2-border-color: #910114;
$user-3-border-color: #0a7502;
$user-4-border-color: #df6800;
$user-5-border-color: #f00187;
$user-6-border-color: #01213f;

.userbox-1 {
    .corner {
        border-top: 3vw solid #1985d0;
    }

    .user-coin {
        span {
            border: 4px solid #1985d0;
            color: #1985d0;
        }
    }

    .slider-navigation {
        button {
            color: #1985d0;
        }
    }

    .flickity-container,
    .collected-items-container {
        border-color: $user-1-border-color;
    }

    h1 {
        span {
            background-color: $user-1-color;

            &:before,
            &:after {
                background-color: $user-1-border-color;
            }
        }
    }
}

.userbox-2 {
    .corner {
        border-top: 3vw solid $user-2-color;
    }

    .user-coin {
        span {
            border: 4px solid $user-2-color;
            color: $user-2-color;
        }
    }

    .slider-navigation {
        button {
            color: $user-2-color;
        }
    }

    .flickity-container,
    .collected-items-container {
        border-color: $user-2-border-color;
    }

    h1 {
        span {
            background-color: $user-2-color;

            &:before,
            &:after {
                background-color: $user-2-border-color;
            }
        }
    }
}

.userbox-3 {
    .corner {
        border-top: 3vw solid $user-3-color;
    }

    .user-coin {
        span {
            border: 4px solid $user-3-color;
            color: $user-3-color;
        }
    }

    .slider-navigation {
        button {
            color: $user-3-color;
        }
    }

    .flickity-container,
    .collected-items-container {
        border-color: $user-3-border-color;
    }

    h1 {
        span {
            background-color: $user-3-color;

            &:before,
            &:after {
                background-color: $user-3-border-color;
            }
        }
    }
}

.userbox-4 {
    .corner {
        border-top: 3vw solid $user-4-color;
    }

    .user-coin {
        span {
            border: 4px solid $user-4-color;
            color: $user-4-color;
        }
    }

    .slider-navigation {
        button {
            color: $user-4-color;
        }
    }

    .flickity-container,
    .collected-items-container {
        border-color: $user-4-border-color;
    }

    h1 {
        span {
            background-color: $user-4-color;

            &:before,
            &:after {
                background-color: $user-4-border-color;
            }
        }
    }
}

.userbox-5 {
    .corner {
        border-top: 3vw solid $user-5-color;
    }

    .user-coin {
        span {
            border: 4px solid $user-5-color;
            color: $user-5-color;
        }
    }

    .slider-navigation {
        button {
            color: $user-5-color;
        }
    }

    .flickity-container,
    .collected-items-container {
        border-color: $user-5-border-color;
    }

    h1 {
        span {
            background-color: $user-5-color;

            &:before,
            &:after {
                background-color: $user-5-border-color;
            }
        }
    }
}

.userbox-6 {
    .corner {
        border-top: 3vw solid $user-6-color;
    }

    .user-coin {
        span {
            border: 4px solid $user-6-color;
            color: $user-6-color;
        }
    }

    .slider-navigation {
        button {
            color: $user-6-color;
        }
    }

    .flickity-container,
    .collected-items-container {
        border-color: $user-6-border-color;
    }

    h1 {
        span {
            background-color: $user-6-color;

            &:before,
            &:after {
                background-color: $user-6-border-color;
            }
        }
    }
}

.userbox-1,
.userbox-2,
.userbox-3 {
    .collected-items-container {
        transform: rotate(0deg) translateY(120%);
        transition: all 0.3s linear;

        &.visible {
            transform: rotate(0deg) translateY(0%);
            transition: all 0.3s linear;
        }
    }
}

.userbox-4,
.userbox-5,
.userbox-6 {
    .collected-items-container {
        transform: rotate(180deg) translateY(120%);
        transition: all 0.3s linear;

        &.visible {
            transform: rotate(180deg) translateY(0%);
            transition: all 0.3s linear;
        }
    }

    .flickity-container {
        transform: rotate(180deg);
    }

    .corner {
    }
}

.user-coin {
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    span {
        font-size: calc(12vw * var(--pxToVw));
        border: calc(2vw * var(--pxToVw)) solid white;
        color: white !important;
    }
}

.logout-btn,
.close-btn {
    color: black;
    position: absolute;
    border: calc(3vw * var(--pxToVw)) solid var(--semilightgrey);
    right: 0;
    bottom: 1rem;

    span {
        color: black !important;
        font-size: calc(40vw * var(--pxToVw));
    }

    background-color: white;
}

.logout-btn,
.close-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(80vw * var(--pxToVw));
    height: calc(80vw * var(--pxToVw));

    svg {
        width: calc(48vw * var(--pxToVw));
        height: calc(48vw * var(--pxToVw));
    }

    .svg-inline--fa.fa-w-16 {
        width: calc(48vw * var(--pxToVw));
        height: calc(48vw * var(--pxToVw));
    }
}

.logout-btn {
    left: unset;
    right: 0;
    transform: rotate(90deg);
    transform-origin: center;
}

.navi-btn {
    border: calc(2vw * var(--pxToVw));
    position: relative;
    bottom: 0px;
    right: 0px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-content: center;

    span {
        color: black !important;
    }

    &.disabled {
        svg {
            opacity: 0.3;
        }
    }
}

.right-navi {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    right: 0px;
    width: calc(100vw * var(--pxToVw));
    height: auto;
    z-index: 1000;
    bottom: 0px;
}

.userbox-1 {
    .dot {
        border: calc(2vw * var(--pxToVw)) solid $user-1-border-color;

        &.is-selected {
            background-color: $user-1-border-color;
        }
    }

    .item {
        border-color: $user-1-color;
    }
}

.userbox-2 {
    .dot {
        border: calc(2vw * var(--pxToVw)) solid $user-2-border-color;

        &.is-selected {
            background-color: $user-2-border-color;
        }
    }

    .item {
        border-color: $user-2-color;
    }
}

.userbox-3 {
    .dot {
        border: calc(2vw * var(--pxToVw)) solid $user-3-border-color;

        &.is-selected {
            background-color: $user-3-border-color;
        }
    }

    .item {
        border-color: $user-3-color;
    }
}

.userbox-4 {
    .dot {
        border: calc(2vw * var(--pxToVw)) solid $user-4-border-color;

        &.is-selected {
            background-color: $user-4-border-color;
        }
    }

    .item {
        border-color: $user-4-color;
    }
}

.userbox-5 {
    .dot {
        border: calc(2vw * var(--pxToVw)) solid $user-5-border-color;

        &.is-selected {
            background-color: $user-5-border-color;
        }
    }

    .item {
        border-color: $user-5-color;
    }
}

.userbox-6 {
    .dot {
        border: calc(2vw * var(--pxToVw)) solid $user-6-border-color;

        &.is-selected {
            background-color: $user-6-border-color;
        }
    }

    .item {
        border-color: $user-6-color;
    }
}

/*
<style>
<style lang="scss">
*/
.flickity,
.flickity-slider,
.flickity-viewport,
.carousel-cell {
    height: 100% !important;
}

.flickity-viewport {
    overflow: hidden;
}

.title {
    background-color: transparent;
    width: 100%;
    position: absolute;
    top: 0vh;
    left: 0px;
    z-index: 999999;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
}

h1 {
    margin: 0;
    display: block;
    color: transparent;
    text-transform: uppercase;
    font-size: calc(34vw * var(--pxToVw));
    padding-left: 2vw;
    padding-right: 1vw;
    width: 100%;

    overflow: hidden;

    span {
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        display: inline-block;
        width: auto;
        position: relative;
        padding: 0 0.3vw;
        height: calc(34vw * var(--pxToVw));

        &:before {
            content: '';
            height: 100%;
            width: 100%;

            position: absolute;
            background-color: red;
            left: -100%;
        }

        &:after {
            content: '';
            height: 100%;
            width: 25vw;
            display: block;
            position: absolute;
            background-color: red;
            right: -25vw;
            top: 0px;
        }
    }

    &.logout {
        position: relative;

        span {
            text-align: right;
        }
    }
}

h1 {
    display: block;
    font-size: calc(34vw * var(--pxToVw));
}

h2 {
    display: block;
    font-size: calc(30vw * var(--pxToVw));
    color: black;
}

p {
    display: block;
    font-size: calc(24vw * var(--pxToVw));
    color: black;
}

.collected-items-container {
    box-shadow: rgba(0, 0, 0, 1) 0px 0px 25px;
}

.flickity-page-dots {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    display: flex;
    margin: 0px;
    padding: 0px;
}

.dot {
    float: left;
    border-width: calc(20vw * var(--pxToVw));
    margin-left: calc(10vw * var(--pxToVw));
    font-size: calc(0vw * var(--pxToVw));
    width: calc(30vw * var(--pxToVw));
    height: calc(30vw * var(--pxToVw));
    position: relative;
    pointer-events: none;
}

.matter {
    position: absolute;

    top: 50%;
    left: 50%;
    width: 70%;
    height: 80%;
    transform: translateX(-50%) translateY(-60%);
}

.matter-inner {
    position: relative;
    width: 100%;
    height: 100%;
}

.item {
    z-index: 9999;
    position: absolute;
    opacity: 0;
    border-style: solid;
    border-width: 0;
}

.preview {
    top: 0px;
    left: 0px;
    width: 100%;
    margin: 1vw 0px;
    position: absolute;
    bottom: unset;
}

.flickity-page-dots {
    height: auto;
    margin-bottom: 0.2vh;
}

.flickity-container {
    padding-bottom: 1vw;
}

.start {
    top: 10%;
    position: absolute;
}

.current-item {
    width: 70%;
    height: 30%;
    position: absolute;
    bottom: 0px;
    background-color: white;
    opacity: 0;
    transition: all 0.3s linear;
    z-index: -1;

    &.fadeInOut {
        z-index: 10;
        opacity: 1;
        transition: all 0.3s linear;
    }

    .flickity-container {
        width: 100%;
        height: 100%;
        border-style: none;
    }
}

.userbox-4,
.userbox-5,
.userbox-6 {
    .current-item {
        bottom: unset;
        top: 0px;
    }
}

.preview {
    height: 100%;

    img {
        height: 100%;
    }
}

.scrollbar {
    bottom: 50%;
    right: 0px;
    position: absolute;
    transform: translateY(-100%);
}

.copyright {
    position: absolute;
    bottom: 0px;
    height: 100%;
    width: 100%;

    .copyright-btn {
        position: absolute;
        bottom: 10px;
        left: 10px;
    }

    &.visible {
        .copyright-body {
            opacity: 1;
            transition: opacity 0.3s linear;
        }
    }

    .copyright-body {
        padding: 10px 10px 40px 10px;
        opacity: 0;
        transition: opacity 0.3s linear;
        background-color: black;
        height: 100%;
        p {
            color: #fff;
        }
    }
}

::-webkit-scrollbar {
    display: none;
}

.fa {
    font-size: calc(36vw * var(--pxToVw)) !important;
}

.user-element {
    .item {
        opacity: 1;
        position: absolute;
        animation: fly-in 2s reverse forwards;
    }

    @keyframes fly-in {
        100% {
            top: 100%;
            left: 0;
        }
    }
}

/**/
.flickity-page-dots {
    display: none;
}

.progress-container {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translate(-50%, -50%);
}

.steps {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: auto;
}

.step {
    width: calc(30vw * var(--pxToVw));
    height: calc(30vw * var(--pxToVw));
    margin-right: calc(30vw * var(--pxToVw));
    background: #fff;
    border: 2px solid #acaca6;
    border-radius: 50%;
    transition: background 1s;

    &:last-child {
        margin-right: 0;
    }
}

.step.selected {
    border: 2px solid $user-1-color;
}

.round-time-bar {
    overflow: hidden;
    position: absolute;
    bottom: 0;
    height: calc(15vw * var(--pxToVw));
    width: 100%;
    left: 0;
}

.round-time-bar div {
    height: calc(15vw * var(--pxToVw));
    width: 0;
}

.round-time-bar[data-style='smooth'] div {
    animation: roundtime calc(var(--duration) * 1s) linear forwards;
}

@keyframes roundtime {
    to {
        /* More performant than `width` */
        transform: scaleX(1);
        width: 100%;
    }
}

.userbox-1 {
    .step.completed {
        border: 2px solid $user-1-color;
        background: $user-1-color;
    }

    .round-time-bar div {
        background: $user-1-color;
    }
}

.userbox-2 {
    .step.completed {
        border: 2px solid $user-2-color;
        background: $user-2-color;
    }

    .round-time-bar div {
        background: $user-2-color;
    }
}

.userbox-3 {
    .step.completed {
        border: 2px solid $user-3-color;
        background: $user-3-color;
    }

    .round-time-bar div {
        background: $user-3-color;
    }
}

.userbox-4 {
    .step.completed {
        border: 2px solid $user-4-color;
        background: $user-4-color;
    }

    .round-time-bar div {
        background: $user-4-color;
    }
}

.userbox-5 {
    .step.completed {
        border: 2px solid $user-5-color;
        background: $user-5-color;
    }

    .round-time-bar div {
        background: $user-5-color;
    }
}

.userbox-6 {
    .step.completed {
        border: 2px solid $user-6-color;
        background: $user-6-color;
    }

    .round-time-bar div {
        background: $user-6-color;
    }
}

#knowledgeNetwork {
    .progress {
        position: absolute;
        width: 100%;
        height: 50%;
        border-bottom: 2px solid #acaca6;
        z-index: -1;
        background: transparent;
    }
}

.percent {
    position: absolute;
    width: 0;
    height: 100%;
    z-index: 1;
    transition: width 1s;
}

.collected-items-container-inner {
    height: 100%;
    width: 100%;
}

.no-collected-items {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
}
</style>
